import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useToggle } from 'react-use';
import {
    Col,
    FormGroup,
    Input,
    Label,
    ModalBody,
    Row,
} from 'reactstrap';

import { ModalContent, PacoModal, WaitingDayHoursInput } from '../../../../@paco/compositions';
import { ConnectedEntityEventLogs } from '../../../../@paco/connectors';
import { PayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../../../@paco/entities/Period/PeriodTransformers';
import { Permission } from '../../../../@paco/entities/Permission/Permission';
import { Role } from '../../../../@paco/entities/Role/Role';
import { transformLegacyRoleToRole } from '../../../../@paco/entities/Role/RoleTransformers';
import useCheckPermission from '../../../../@paco/helpers/hooks/useCheckPermission';
import { EventLogEntityType } from '../../../../@paco/types/eventLogTypes';
import FormDatePicker from '../../../../components/FormDatePicker/FormDatePicker';
import FormFooter from '../../../../components/forms/FormFooter/FormFooter';
import LeaveOfAbsenceInfo from '../../../../components/LeaveOfAbsenceRequestCard/LeaveOfAbsenceInfo';
import LegacyHoursWarning from '../../../../components/LegacyHoursWarning/LegacyHoursWarning';
import ModalHeader from '../../../../components/ModelHeader/ModalHeader';
import WeekdayHoursInputs from '../../../../components/WeekHoursInputs/WeekHoursInputs';
import { formatDate } from '../../../../helpers/date';
import useUserHasWaitingDayHours from '../../../../helpers/hooks/useUserHasWaitingDayHours';
import { getPermissionToAdd, getPermissionToDelete, getPermissionToEdit } from '../../../../helpers/permissions/getPermissionToAction';
import { translate } from '../../../../helpers/translations/translator';
import {
    Absence,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../../models';
import transformAbsenceHoursToWaitingDayHours from '../../../../services/AbsenceHoursService/transformAbsenceHoursToWaitingDayHours';
import transformWeeksWithHoursToPayrollPeriods from '../../../../services/WeekWithHours/transformWeeksWithHoursToPayrollPeriods';
import { isStartDateLaterThanEndDate } from '../../helpers';
import getAbsencePayrollPeriodEndDate from './helpers/getAbsencePayrollPeriodEndDate';
import { transformAbsenceHoursToWeekDayWithHours } from './helpers/transformAbsenceHoursToWeekDayWithHours';

interface EditAbsenceFormProps {
    currentUserRole?: Role;
    data: Absence;
    pacoPayrollPeriods: PayrollPeriod[];
    payrollPeriods: PayrollPeriodViewModel[];
    permissions: Permission[];
    onCancel: () => void;
    onSubmit: (
        absence: Absence,
        startDate: string,
        endDate: string | null,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        waitingDayHours: number,
    ) => void;
    onDelete?: (id: string) => void;
}

const dateFormat = 'yyyy-MM-dd';
const timeFormat = 'HH:mm';

const EditAbsenceForm: FC<EditAbsenceFormProps> = ({
    currentUserRole,
    data,
    pacoPayrollPeriods,
    payrollPeriods,
    permissions = [],
    onCancel,
    onSubmit,
    onDelete,
}) => {
    const [startDate, setStartDate] = useState<string | null>('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState<string | null>('');
    const [endTime, setEndTime] = useState('');
    const [waitingDayHours, setWaitingDayHours] = useState<number | null>(null);
    const [weeksWithHours, setWeekdaysWithHours] = useState<WeekWithHours[]>([]);
    const [legacyWeeksWithHours, setLegacyWeeksWithHours] = useState<WeekWithHours[]>([]);
    const [showHistory, toggleShowHistory] = useToggle(false);

    const start = useMemo(() => (
        startDate ? `${formatDate(startDate, 'yyyy-MM-dd')}T${startTime}:00` : ''
    ), [startDate, startTime]);
    const absencesUserRoles = useMemo(() => data.user.roles.map(transformLegacyRoleToRole), [data]);
    const canEditAbsence = useMemo(() => getPermissionToEdit(currentUserRole, absencesUserRoles, permissions, 'absences'), []);
    const canDeleteAbsence = useMemo(() => getPermissionToDelete(currentUserRole, absencesUserRoles, permissions, 'absences'), []);
    const canAddAbsenceHours = useMemo(() => getPermissionToAdd(currentUserRole, absencesUserRoles, permissions, 'absence-hours'), []);
    const canEditAbsenceHours = useMemo(() => getPermissionToEdit(currentUserRole, absencesUserRoles, permissions, 'absence-hours'), []);
    const canDeleteAbsenceHours = useMemo(() => getPermissionToDelete(currentUserRole, absencesUserRoles, permissions, 'absence-hours'), []);
    const canViewHistory = useCheckPermission('view-all-logs');

    const canModifyAbsenceHours = canEditAbsenceHours && canDeleteAbsenceHours && canAddAbsenceHours;
    const isAllPeriodWeeksLocked = weeksWithHours.every(week => week.payrollPeriodWeeks.every(period => period.isLocked));

    const [userHasWaitingDayHours, waitingDayHoursLoading] = useUserHasWaitingDayHours(data, start);

    const dateError = isStartDateLaterThanEndDate(startDate, startTime, endDate, endTime);
    const payrollPeriodsStartDate = (startDate && startTime) ? `${startDate}T${startTime}` : null;
    const payrollPeriodsEndDate = useMemo(() => getAbsencePayrollPeriodEndDate(payrollPeriodsStartDate, endDate, startTime), [payrollPeriodsStartDate, endDate, startTime]);

    const validEndDate = (!endDate && !endTime) || (endDate && endTime.length);
    const canSubmit = !!(startDate && startTime.length && validEndDate && !dateError);

    const payrollPeriodError = useMemo(() => {
        if (!payrollPeriodsStartDate || !payrollPeriodsEndDate) {
            return false;
        }

        return doesPeriodOverlapWithLockedPayrollPeriod(
            transformToPeriod(new Date(payrollPeriodsStartDate), new Date(payrollPeriodsEndDate.date)),
            pacoPayrollPeriods,
        );
    }, [startDate, endDate, payrollPeriods]);

    useEffect(() => {
        if (data) {
            const weekdays = transformAbsenceHoursToWeekDayWithHours(
                data.absenceHours || [],
                pacoPayrollPeriods,
            );

            setWeekdaysWithHours(weekdays);
            setLegacyWeeksWithHours(weekdays);
            setStartDate(formatDate(data.start, dateFormat));
            setStartTime(formatDate(data.start, timeFormat));
            setEndDate(data.end ? formatDate(data.end, dateFormat) : null);
            setEndTime(data.end ? formatDate(data.end, timeFormat) : '');
            setWaitingDayHours(transformAbsenceHoursToWaitingDayHours(data.absenceHours));
        }
    }, [data]);

    const onFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const end = endDate ? `${formatDate(endDate, 'yyyy-MM-dd')} ${endTime}:00` : null;
        const payrollPeriodsWithHours = transformWeeksWithHoursToPayrollPeriods(weeksWithHours, payrollPeriods);

        onSubmit(
            data,
            start,
            end,
            canModifyAbsenceHours ? payrollPeriodsWithHours : [],
            canModifyAbsenceHours ? weeksWithHours : [],
            waitingDayHours || 0,
        );
    };

    const onChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value);
    };

    const onChangeStartDate = (date: Date) => {
        setStartDate(date ? formatDate(date, dateFormat) : null);
    };

    const onChangeEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndTime(e.target.value);
    };

    const onChangeEndDate = (date: Date) => {
        setEndDate(date ? formatDate(date, dateFormat) : null);
    };

    const onChangeWaitingDayHours = (value: number | undefined) => {
        setWaitingDayHours(value === undefined ? null : value);
    };

    const onDeleteClick = () => {
        if (onDelete) onDelete(data.id);
    };

    const onWeekdayHoursInputsChange = (weekdays: WeekWithHours[]) => {
        setWeekdaysWithHours(weekdays);
    };

    if (!data) {
        return null;
    }

    return (
        <form onSubmit={onFormSubmit}>
            <ModalHeader
                showHistoryButton={canViewHistory}
                title={translate('pages.absences.editAbsence')}
                isLoading={waitingDayHoursLoading}
                onHistoryButtonClick={toggleShowHistory}
            />
            <ModalBody>
                <Row>
                    <Col>
                        <LeaveOfAbsenceInfo
                            absence={data}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.startDate')}</Label>
                            <FormDatePicker
                                id="startDate"
                                selected={startDate}
                                onChange={onChangeStartDate}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.startTime')}</Label>
                            <Input
                                type="time"
                                className="form-control"
                                id="startTime"
                                name="startTime"
                                autoComplete="off"
                                value={startTime}
                                onChange={onChangeStartTime}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.endDate')}</Label>
                            <FormDatePicker
                                id="endDate"
                                selected={endDate}
                                onChange={onChangeEndDate}
                                invalid={dateError}
                                feedback={translate('pages.management.endDateNeedsToBeLaterThanStartDate')}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.endTime')}</Label>
                            <Input
                                type="time"
                                className="form-control"
                                id="endTime"
                                name="endTime"
                                autoComplete="off"
                                value={endTime || ''}
                                onChange={onChangeEndTime}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LegacyHoursWarning
                            weekWithHours={legacyWeeksWithHours}
                            type="absences"
                        />
                    </Col>
                </Row>
                {userHasWaitingDayHours && (
                    <Row>
                        <Col>
                            <WaitingDayHoursInput
                                isDisabled={!canModifyAbsenceHours || isAllPeriodWeeksLocked}
                                value={waitingDayHours === null ? undefined : waitingDayHours}
                                onChange={onChangeWaitingDayHours}
                            />
                        </Col>
                        <Col />
                    </Row>
                )}
                <Row>
                    <Col>
                        <WeekdayHoursInputs
                            disabled={!canModifyAbsenceHours}
                            pacoPayrollPeriods={pacoPayrollPeriods}
                            payrollPeriods={payrollPeriods}
                            weekWithHours={weeksWithHours}
                            type="absences"
                            startDate={
                                payrollPeriodsStartDate ? new Date(payrollPeriodsStartDate) : null
                            }
                            endDate={payrollPeriodsEndDate}
                            onChange={onWeekdayHoursInputsChange}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <FormFooter
                isDisabled={!canEditAbsence}
                canSubmit={canSubmit}
                onCancel={onCancel}
                onDelete={canDeleteAbsence ? onDeleteClick : undefined}
                deleteButtonError={payrollPeriodError ? translate('common.datesOverlapWithLockedPayrollPeriod') : undefined}
            />

            {showHistory && (
                <PacoModal isWide>
                    <ModalContent
                        showCloseButton
                        title={translate('pages.absences.absenceLog')}
                        onCloseButtonClick={toggleShowHistory}
                    >
                        <ConnectedEntityEventLogs
                            entityId={data.id}
                            entityType={EventLogEntityType.absence}
                        />
                    </ModalContent>
                </PacoModal>
            )}
        </form>
    );
};

export default EditAbsenceForm;
