import React, { FC, ReactElement, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
    addDays,
    addSeconds,
    getEndOfWorkDay,
    getStartOfWorkDay,
} from '../../../../@paco/helpers/date';
import { setUserSearch } from '../../../../@paco/redux/@interface/globalFilters/globalFiltersReducer';
import { getPacoPayrollPeriods } from '../../../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import {
    setIsDeleteSuccessful as setIsShiftConceptPlanningDeleteSuccessful,
    setIsEditSuccessful as setIsShiftConceptPlanningSuccessful,
} from '../../../../@paco/redux/shiftConceptPlanning/shiftConceptPlanningReducer';
import { fetchSpecialDays } from '../../../../@paco/redux/specialDays/specialDaysActions';
import { useTypedSelector } from '../../../../@paco/redux/store';
import { TimeModeType } from '../../../../@paco/types';
import { PzLeaveType } from '../../../../@paco/types/leaveType';
import NewFilters from '../../../../components/Filters/Filters';
import SidebarPage from '../../../../components/SidebarPage/SidebarPage';
import { CONCEPTS_LIST_VIEW } from '../../../../constants';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { checkPermission } from '../../../../helpers';
import {
    Absence,
    LeaveOfAbsence,
    LeaveOfAbsenceEditType,
    LeaveOfAbsenceFormData,
    LeaveType,
    PayrollPeriodViewModel,
    PayrollPeriodWithHours,
    WeekWithHours,
} from '../../../../models';
import removeShiftConceptPlanningFromUsersWithConceptAvailability from '../../../../redux/@toolkit/shiftConcepts/helpers/removeShiftPlanningFromUsersWithConceptAvailability';
import { updateUsersWithConceptAvailabilityWithShiftPlanning } from '../../../../redux/@toolkit/shiftConcepts/helpers/updateUsersWithConceptAvailabilityWithShiftPlanning';
import { fetchShiftConceptPlannings, getUsersListViewData, planAvailabilityUserToShiftConcept } from '../../../../redux/@toolkit/shiftConcepts/shiftConceptsActions';
import { setAvailabilityPagination, setUserWithConceptAvailability } from '../../../../redux/@toolkit/shiftConcepts/shiftConceptsReducer';
import { deleteAbsence, editAbsence, editLeaveOfAbsence } from '../../../../redux/absences-ts/absencesActions';
import { getPayrollPeriods, switchMode } from '../../../../redux/app/appActions';
import { setFilter } from '../../../../redux/filter/filterActions';
import { AppFilter } from '../../../../redux/filter-ts/filterModels';
import { clearResourceLogs, getResourceLogs } from '../../../../redux/logs/logsActions';
import { setUserPlanningPreference } from '../../../../redux/management/managementActions';
import { Reducers } from '../../../../redux/reducers';
import { TypedDispatch } from '../../../../redux/store';
import { setTimeMode } from '../../../../redux/weekNavigator/weekNavigatorActions';
import ConceptsListView from '../../containers/ConceptsListView/ConceptsListView';

const ConnectedConceptsListView: FC = (): ReactElement => {
    const dispatch = useDispatch<TypedDispatch>();

    const { selectedDays, timeMode } = useTypedSelector(state => state.calendarReducer);
    const { isLoading, isSuccessful } = useSelector((state: Reducers) => state.shiftConceptsReducer);
    const {
        isLoading: isShiftConceptPlanningLoading,
        isDeleteSuccessful: isDeleteShiftConceptPlanningSuccessful,
        isEditSuccessful: isEditShiftConceptPlanningSuccessful,
        deletedShiftConceptPlanningId,
        shiftConceptPlanning,
    } = useSelector((state: Reducers) => state.shiftConceptPlanningReducer);
    const { payrollPeriods } = useTypedSelector(state => state.pacoPayrollPeriodsReducer);
    const legacyPayrollPeriods = useSelector<Reducers, PayrollPeriodViewModel[]>(state => state.appReducer.payrollPeriods);
    const { specialDays } = useSelector((state: Reducers) => state.specialDaysReducer);
    const { permissions, role: currentUserRole } = useTypedSelector(state => state.authenticatedUserReducer);
    const users = useSelector((state: Reducers) => state.shiftConceptsReducer.usersWithConceptAvailability);
    const pagination = useSelector((state: Reducers) => state.shiftConceptsReducer.availabilityPagination);
    const shiftsLoading = useSelector((state: Reducers) => state.shiftsReducer.loading);
    const absencesLoading = useSelector((state: Reducers) => state.absencesReducer.loading);
    const managementLoading = useSelector((state: Reducers) => state.managementReducer.loading);
    const { logs, loading: logsLoading } = useSelector((state: Reducers) => state.logsReducer);
    const filter = useSelector((state: Reducers) => state.filterReducer.filter);
    const { departmentOptions, userSearch } = useTypedSelector(state => state.globalFiltersReducer);
    const loading = shiftsLoading || absencesLoading || managementLoading || isShiftConceptPlanningLoading || isLoading;

    const canAddPlannings = checkPermission(permissions, 'add-new-shift-plannings');
    const canViewShiftConcepts = checkPermission(permissions, 'view-all-shift-concepts');

    const startDate = getStartOfWorkDay(selectedDays[0]);
    const endDate = getEndOfWorkDay(selectedDays[selectedDays.length - 1]);

    const getUsersData = (): void => {
        dispatch(getUsersListViewData());
    };

    const handleSearchBarChange = (search: string): void => {
        dispatch(setUserSearch(search));
    };

    const onSetFilterChange = (newFilter: AppFilter): void => {
        dispatch(setFilter(newFilter, getUsersData));
    };

    const dispatchPlanUserToShiftConcept = (userId: string, shiftConcept: ShiftConceptViewModelOld): void => {
        dispatch(planAvailabilityUserToShiftConcept(userId, shiftConcept));
    };

    const dispatchEditLeaveOfAbsence = (
        editType: LeaveOfAbsenceEditType,
        data: LeaveOfAbsenceFormData,
        payrollPeriodsWithHours: PayrollPeriodWithHours[],
        weeksWithHours: WeekWithHours[],
        activeLeaveOfAbsence: LeaveOfAbsence,
        leaveType?: LeaveType | PzLeaveType | null,
    ): void => {
        dispatch(editLeaveOfAbsence({
            editType,
            data,
            payrollPeriodsWithHours,
            weeksWithHours,
            leaveOfAbsence: activeLeaveOfAbsence as LeaveOfAbsence,
            leaveType,
            page: CONCEPTS_LIST_VIEW,
        }));
    };

    const dispatchDeclineLeaveOfAbsence = (
        declineComment: string,
        editLOAData: LeaveOfAbsenceFormData,
        editLOAPayrollPeriodHours: PayrollPeriodWithHours[],
        editLOAWeekdayHours: WeekWithHours[],
        activeLeaveOfAbsence: LeaveOfAbsence,
    ): void => {
        dispatch(editLeaveOfAbsence({
            editType: LeaveOfAbsenceEditType.deny,
            data: editLOAData,
            payrollPeriodsWithHours: editLOAPayrollPeriodHours,
            weeksWithHours: editLOAWeekdayHours,
            leaveOfAbsence: activeLeaveOfAbsence,
            declineComment,
            page: CONCEPTS_LIST_VIEW,
        }));
    };

    const dispatchEditAbsence = (
        absence: Absence,
        absenceStartDate: string,
        absenceEndDate: string | null,
        periods: PayrollPeriodWithHours[],
        weekdays: WeekWithHours[],
        waitingDayHours: number,
    ): void => {
        dispatch(editAbsence(
            absence,
            absenceStartDate,
            absenceEndDate,
            periods,
            weekdays,
            waitingDayHours,
            CONCEPTS_LIST_VIEW,
        ));
    };

    const dispatchDeleteAbsence = (absenceId: string, pageNumber: string): void => {
        dispatch(deleteAbsence(absenceId, pageNumber));
    };

    const dispatchAvailabilityTime = (
        weekday: number,
        fromDate: string,
        toDate: string,
        comment: string,
        userId: string,
        id: string,
    ): void => {
        dispatch(setUserPlanningPreference(
            weekday,
            fromDate,
            toDate,
            comment,
            userId,
            id,
            CONCEPTS_LIST_VIEW,
        ));
    };

    const dispatchGetLeaveOfAbsencesLogs = (id: string): void => {
        dispatch(getResourceLogs(id, 'leave-of-absences'));
    };

    const dispatchCloseLogsModal = (): void => {
        dispatch(clearResourceLogs());
    };

    const handlePagination = (page: number): void => {
        dispatch(setAvailabilityPagination(page));
        getUsersData();
    };

    const handleCalendarTypeChange = (): void => {
        dispatch(switchMode());
    };

    useEffect((): void => {
        if (timeMode !== TimeModeType.week && timeMode !== TimeModeType.custom) {
            dispatch(setTimeMode(TimeModeType.week));
        }

        if (!legacyPayrollPeriods.length) {
            dispatch(getPayrollPeriods());
        }

        if (!payrollPeriods.length) {
            dispatch(getPacoPayrollPeriods());
        }
    }, []);

    useEffect((): void => {
        dispatch(fetchSpecialDays(addDays(startDate, -1), addSeconds(endDate, 1)));
    }, [selectedDays]);

    useEffect((): void => {
        if (isSuccessful) {
            dispatch(fetchShiftConceptPlannings(pagination.number));
        }
    }, [isSuccessful]);

    useEffect((): void => {
        if (isEditShiftConceptPlanningSuccessful && shiftConceptPlanning) {
            const updatedUsers = updateUsersWithConceptAvailabilityWithShiftPlanning(users, shiftConceptPlanning);

            dispatch(setIsShiftConceptPlanningSuccessful(false));
            dispatch(setUserWithConceptAvailability(updatedUsers));
        }
    }, [isEditShiftConceptPlanningSuccessful]);

    useEffect((): void => {
        if (isDeleteShiftConceptPlanningSuccessful && deletedShiftConceptPlanningId) {
            const updatedUsers = removeShiftConceptPlanningFromUsersWithConceptAvailability(users, deletedShiftConceptPlanningId);

            dispatch(setIsShiftConceptPlanningDeleteSuccessful(false));
            dispatch(setUserWithConceptAvailability(updatedUsers));
        }
    }, [isDeleteShiftConceptPlanningSuccessful]);

    useEffect((): void => {
        dispatch(setAvailabilityPagination(1));
    }, [departmentOptions, userSearch]);

    useEffect((): void => {
        getUsersData();
    }, [selectedDays, departmentOptions, userSearch]);

    return (
        <SidebarPage
            sidebarContent={(
                <NewFilters
                    filter={filter}
                    filterBlocks={[
                        'availability',
                        'availability-sorting',
                        'main-department',
                        'employment-type-badge',
                        'show-published-shifts',
                    ]}
                    permissions={permissions}
                    onChange={onSetFilterChange}
                />
            )}
        >
            <ConceptsListView
                canViewShiftConcepts={canViewShiftConcepts}
                days={selectedDays}
                loading={loading}
                logsLoading={logsLoading}
                filter={filter}
                users={users}
                pagination={pagination}
                pacoPayrollPeriods={payrollPeriods}
                payrollPeriods={legacyPayrollPeriods}
                canAddPlannings={canAddPlannings}
                specialDays={specialDays}
                startDate={startDate}
                endDate={endDate}
                permissions={permissions}
                currentUserRole={currentUserRole}
                logs={logs}
                onSearchBarChange={handleSearchBarChange}
                onPagination={handlePagination}
                onCloseLogsModalClick={dispatchCloseLogsModal}
                onCalendarTypeChange={handleCalendarTypeChange}
                onShowLeaveOfAbsencesLogsClick={dispatchGetLeaveOfAbsencesLogs}
                dispatchPlanUserToShiftConcept={dispatchPlanUserToShiftConcept}
                dispatchEditLeaveOfAbsence={dispatchEditLeaveOfAbsence}
                dispatchDeclineLeaveOfAbsence={dispatchDeclineLeaveOfAbsence}
                dispatchEditAbsence={dispatchEditAbsence}
                dispatchDeleteAbsence={dispatchDeleteAbsence}
                dispatchAvailabilityTime={dispatchAvailabilityTime}
            />
        </SidebarPage>
    );
};

export default ConnectedConceptsListView;
