import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Col,
    CustomInput,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { SelectPayrollPeriod } from '../../../@paco/compositions';
import { ConnectedMultiUsersSelect } from '../../../@paco/connectors';
import { PayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriod';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodTransformers';
import { BasicUser } from '../../../@paco/entities/User/User';
import {
    addHours,
    getEndOfWorkDay,
    getStartOfWorkDay,
    startOfDayInHours,
} from '../../../@paco/helpers/date';
import { TrackExportType } from '../../../@paco/types/trackExportType';
import { generateSelectOptions } from '../../../helpers';
import { addSeconds, compareAsc } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';
import { PayrollPeriodViewModel } from '../../../models';
import { transformPayrollPeriodToLegacyPayrollPeriod } from '../../../services/PayrollPeriodService/transformPayrollPeriodLegacy';
import { RadioButtonsList, RadioListButton } from '../../RadioButtonsList/RadioButtonsList';
import { SelectDateRange } from '../SelectDateRange/SelectDateRange';

import './ExportRangeForm.scss';

enum DateType {
    period = 'period',
    date = 'date',
}

interface ExportRangeFormProps {
    activePeriod?: PayrollPeriodViewModel;
    endDate?: Date;
    exportTypes?: TrackExportType[];
    intro?: string;
    payrollPeriods: PayrollPeriodViewModel[];
    startDate: Date;
    title: string;
    onCancel: () => void;
    onSubmit: (
        startDate: Date,
        endDate: Date,
        payrollPeriodIds: string[],
        userIds: string[],
        exportType: TrackExportType,
    ) => void;
}

const generateRadioListButtons = (exportType: TrackExportType): RadioListButton[] => [
    {
        label: translate('common.selectPeriod'),
    },
    ...(exportType === TrackExportType.tracks ? [
        {
            label: translate('common.selectDate'),
        },
    ] : []),
];

const ExportRangeForm: FC<ExportRangeFormProps> = ({
    exportTypes = [TrackExportType.loket, TrackExportType.hourOverview, TrackExportType.tracks],
    intro,
    title,
    ...props
}) => {
    const transformedActivePeriod = useMemo(() => (props.activePeriod ? transformLegacyPayrollPeriodToPayrollPeriod(props.activePeriod) : undefined), [props.activePeriod]);
    const transformedPayrollPeriods = useMemo(() => props.payrollPeriods
        .map(payrollPeriod => transformLegacyPayrollPeriodToPayrollPeriod(payrollPeriod)), [props.payrollPeriods]);

    const [startDate, setStartDate] = useState(addHours(props.startDate, -startOfDayInHours) || new Date());
    const transformedEndDate = props.endDate ? addHours(addSeconds(props.endDate, -1), -startOfDayInHours) : new Date();
    const [endDate, setEndDate] = useState(transformedEndDate);
    const [payrollPeriodIds, setPayrollPeriodIds] = useState<string[]>(transformedActivePeriod ? [transformedActivePeriod.id] : []);
    const [users, setUsers] = useState<BasicUser[]>([]);
    const [dateType, setDateType] = useState<DateType>(DateType.date);
    const [exportType, setTrackExportType] = useState<TrackExportType>(exportTypes[0]);

    const exportTypesOptions = generateSelectOptions(exportTypes);
    const buttons = generateRadioListButtons(exportType);
    const dateError = compareAsc(startDate, endDate) === 1;
    const noPeriodsError = (dateType === 'period' && !props.payrollPeriods.length);
    const canSubmit = !dateError && !noPeriodsError && payrollPeriodIds.length;

    useEffect(() => {
        if (props.payrollPeriods.length) {
            setDateType(DateType.period);
        }
    }, [props.payrollPeriods]);

    useEffect(() => {
        setPayrollPeriodIds(transformedActivePeriod ? [transformedActivePeriod.id] : []);
        setUsers([]);
    }, [exportType]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit(
            getStartOfWorkDay(startDate),
            getEndOfWorkDay(endDate),
            payrollPeriodIds,
            users.map(user => user.id),
            exportType,
        );
    };

    const onTrackExportTypeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newTrackExportType = e.target.value as TrackExportType;
        setDateType(newTrackExportType !== TrackExportType.tracks ? DateType.period : dateType);
        setTrackExportType(newTrackExportType);
    };

    const onSelectDateTypeChange = (index: number): void => {
        setDateType(index ? DateType.date : DateType.period);
    };

    const onSelectDateRangeChange = (date1: Date, date2: Date): void => {
        setStartDate(date1);
        setEndDate(date2);
    };

    const onPeriodChange = (periods: PayrollPeriodViewModel[]): void => {
        if (periods.length) {
            setStartDate(addHours(periods[0].start, -startOfDayInHours));
            setEndDate(addHours(addSeconds(periods[0].end.date, -1), -startOfDayInHours));
        }
        setPayrollPeriodIds(periods.map(period => period.id));
    };

    const handlePeriodSelect = (periods: PayrollPeriod[]): void => {
        onPeriodChange(periods.map(transformPayrollPeriodToLegacyPayrollPeriod));
    };

    return (
        <form onSubmit={onSubmit} className="export-range-form">
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <div className="export-range-form__intro">
                    <div className="export-range-form__info-icon" />
                    <p>
                        {intro || translate('common.selectDateRangeForExport')}
                    </p>
                </div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('pages.tracks.exportType')}</Label>
                            <CustomInput
                                type="select"
                                name="break-time"
                                id="break-time"
                                onChange={onTrackExportTypeChange}
                            >
                                {exportTypesOptions}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                </Row>
                {buttons.length > 1 && (
                    <Row>
                        <Col>
                            <FormGroup>
                                <RadioButtonsList
                                    checked={dateType === 'period' ? 0 : 1}
                                    buttons={buttons}
                                    onRadioChange={onSelectDateTypeChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                )}

                <Row>
                    {dateType === 'date' ? (
                        <SelectDateRange
                            onChange={onSelectDateRangeChange}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    ) : (
                        <SelectPayrollPeriod
                            isMultiple={exportType === TrackExportType.hourOverview}
                            periods={transformedPayrollPeriods}
                            activePeriod={transformedActivePeriod}
                            onChange={handlePeriodSelect}
                            className="export-range-form__select-payroll-period"
                            colClassName="export-range-form__select-payroll-period-col"
                        />
                    )}
                </Row>

                {exportType === TrackExportType.hourOverview && (
                    <Row className="export-range-form__select-users-row">
                        <Col>
                            <Label className="export-range-form__label">{translate('pages.tracks.filterByEmployee')}</Label>
                            <ConnectedMultiUsersSelect onSelect={setUsers} />
                        </Col>
                    </Row>
                )}
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="link" id="modal-close" onClick={props.onCancel}>{translate('common.cancel')}</Button>
                <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.export')}</Button>
            </ModalFooter>
        </form>
    );
};

export default ExportRangeForm;
