import React, { Component } from 'react';

import PropTypes from 'prop-types';
import {
    Button,
    Col,
    CustomInput,
    FormFeedback,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    Row,
} from 'reactstrap';

import { ModalContent, PacoModal, WaitingDayHoursInput } from '../../../@paco/compositions';
import { ConnectedEntityEventLogs } from '../../../@paco/connectors';
import { transformLegacyRoleToRole } from '../../../@paco/entities/Role/RoleTransformers';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import ModalHeader from '../../../components/ModelHeader/ModalHeader';
import UserProfileInfo from '../../../components/UserProfileInfo/UserProfileInfo';
import WeekdayHoursInputs from '../../../components/WeekHoursInputs/WeekHoursInputs';
import {
    checkPermission,
    getObjProperty,
    getPermissionToDelete,
    getUserFullName,
} from '../../../helpers';
import { compareAsc, formatDate, max } from '../../../helpers/date';
import UpToAndIncludingDate from '../../../helpers/date/UpToAndIncludingDate';
import { getPermissionToAdd, getPermissionToEdit } from '../../../helpers/permissions/getPermissionToAction';
import { translate } from '../../../helpers/translations/translator';
import transformAbsenceHoursToWaitingDayHours
    from '../../../services/AbsenceHoursService/transformAbsenceHoursToWaitingDayHours';
import transformWeeksWithHoursToPayrollPeriods from '../../../services/WeekWithHours/transformWeeksWithHoursToPayrollPeriods';
import getAbsencesForEmployeeInLast4Weeks from '../helpers/getAbsencesForEmployeeInLast4Weeks';
import { transformAbsenceHoursToWeekDayWithHours } from './EditAbsenceForm/helpers/transformAbsenceHoursToWeekDayWithHours';

import './Forms.scss';


class ResolveAbsenceForm extends Component {
    state = {
        endDate: null,
        endTime: '',
        canEditAbsencesHours: false,
        canSubmitDate: true,
        canSubmitTime: true,
        canSubmit: true,
        canViewLogs: true,
        showHistory: false,
        waitingDayHours: null,
        userHasWaitingDayHours: false,
        userHasWaitingDayHoursLoading: false,
    };

    componentDidMount() {
        const start = getObjProperty(this.props, 'absence.start');
        this.doesUserHaveWaitingDayHours();
        this.setState({
            endDate: formatDate(max([new Date(), start]), 'yyyy-MM-dd'),
            endTime: formatDate(new Date(), 'HH:mm:00'),
            waitingDayHours: transformAbsenceHoursToWaitingDayHours(this.props.absence.absenceHours),
        });
    }

    static getDerivedStateFromProps(props, state) {
        const { absence, currentUserRole, permissions } = props;
        const { endDate, endTime } = state;
        const compareDatesResult = compareAsc(endDate, formatDate(absence.start, 'yyyy-MM-dd'));
        const canSubmitDate = compareDatesResult >= 0;
        const canSubmitTime = compareDatesResult !== 0 ? true : endTime >= formatDate(absence.start, 'HH:mm:ss');
        const canSubmit = canSubmitDate && canSubmitTime && endTime && endTime.length;
        const absenceUserRoles = absence.user.roles.map(transformLegacyRoleToRole);
        const canAddAbsencesHours = getPermissionToAdd(currentUserRole, absenceUserRoles, permissions, 'absence-hours');
        const canEditAbsencesHours = getPermissionToEdit(currentUserRole, absenceUserRoles, permissions, 'absence-hours');
        const canDeleteAbsencesHours = getPermissionToDelete(currentUserRole, permissions, 'absence-hours');
        const canViewLogs = checkPermission(permissions, 'view-all-logs', 'resolve-absence-form');

        return {
            ...state,
            canEditAbsencesHours: canEditAbsencesHours && canAddAbsencesHours && canDeleteAbsencesHours,
            canSubmitDate,
            canSubmitTime,
            canSubmit,
            canViewLogs,
        };
    }

    doesUserHaveWaitingDayHours = async () => {
        const { absence } = this.props;
        const { user, start } = absence;

        this.setState({
            userHasWaitingDayHoursLoading: true,
        });

        const absences = await getAbsencesForEmployeeInLast4Weeks(user.id, start, absence.id);

        this.setState({
            userHasWaitingDayHoursLoading: false,
            userHasWaitingDayHours: !absences.length,
        });
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        const { absence, payrollPeriods } = this.props;
        const {
            canEditAbsencesHours,
            endDate,
            endTime,
            weeksWithHours,
            waitingDayHours,
        } = this.state;
        const payrollPeriodsWithHours = transformWeeksWithHoursToPayrollPeriods(weeksWithHours, payrollPeriods);

        this.props.onSubmit(
            absence,
            absence.start,
            `${endDate} ${endTime}`,
            canEditAbsencesHours ? payrollPeriodsWithHours : [],
            canEditAbsencesHours ? weeksWithHours : [],
            waitingDayHours,
        );
    };

    onChangeDate = (val) => {
        this.setState({
            endDate: val ? formatDate(val, 'yyyy-MM-dd') : null,
        });
    };

    onChangeTime = (val) => {
        this.setState({
            endTime: val.target.value,
        });
    };

    onChangeWaitingDayHours = (value) => {
        this.setState({
            waitingDayHours: value === undefined ? null : value,
        });
    };

    onWeekdayHoursInputsChange = (weeksWithHours) => {
        this.setState({
            weeksWithHours,
        });
    };

    toggleShowHistory = () => {
        const { showHistory } = this.state;

        this.setState({
            showHistory: !showHistory,
        });
    };

    render() {
        const {
            endDate,
            endTime,
            canEditAbsencesHours,
            canSubmitDate,
            canSubmitTime,
            canSubmit,
            canViewLogs,
            waitingDayHours,
            showHistory,
            userHasWaitingDayHours,
            userHasWaitingDayHoursLoading,
        } = this.state;
        const {
            onCancel,
            payrollPeriods,
            pacoPayrollPeriods,
            absence = {},
        } = this.props;
        const { user } = absence;
        const payrollPeriodsEndDate = (endDate && endTime) ? `${endDate} ${endTime}` : null;
        const weekWithHours = transformAbsenceHoursToWeekDayWithHours(
            absence.absenceHours || [],
            pacoPayrollPeriods,
        );
        const isAllPeriodWeeksLocked = (this.state.weeksWithHours || []).every(week => week.payrollPeriodWeeks.every(period => period.isLocked));

        return (
            <form ref={this.formRef} onSubmit={this.onFormSubmit}>
                <ModalHeader
                    showHistoryButton={canViewLogs}
                    title={translate('pages.absences.resolveAbsenceFrom')}
                    isLoading={userHasWaitingDayHoursLoading}
                    onHistoryButtonClick={this.toggleShowHistory}
                />
                <ModalBody>
                    { user && (
                        <Row>
                            <Col>
                                <FormGroup>
                                    <UserProfileInfo
                                        date={new Date(absence.start)}
                                        experience={user?.experience?.name}
                                        name={getUserFullName(user)}
                                        userId={user.id}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.endDate')}</Label>
                                <FormDatePicker
                                    invalid={!canSubmitDate}
                                    selected={endDate}
                                    onChange={val => this.onChangeDate(val)}
                                    feedback={translate('pages.management.endDateNeedsToBeLaterThanStartDate')}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FormGroup>
                                    <Label>{translate('pages.tracks.endTime')}</Label>
                                    <CustomInput
                                        invalid={!canSubmitTime}
                                        type="time"
                                        className="form-control"
                                        id="end-time"
                                        name="endTime"
                                        onChange={val => this.onChangeTime(val)}
                                        defaultValue={this.state.endTime}
                                    />
                                    <FormFeedback>
                                        {translate('pages.management.endTimeNeedsToBeLaterOrEqualToStartTime')}
                                    </FormFeedback>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    { userHasWaitingDayHours && (
                        <Row>
                            <Col>
                                <WaitingDayHoursInput
                                    isDisabled={!canEditAbsencesHours || isAllPeriodWeeksLocked}
                                    value={waitingDayHours === null ? undefined : waitingDayHours}
                                    onChange={this.onChangeWaitingDayHours}
                                />
                            </Col>
                            <Col />
                        </Row>
                    )}
                    <WeekdayHoursInputs
                        disabled={!canEditAbsencesHours}
                        payrollPeriods={payrollPeriods}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        weekWithHours={weekWithHours}
                        type="absences"
                        startDate={new Date(absence.start)}
                        endDate={new UpToAndIncludingDate(payrollPeriodsEndDate)}
                        onChange={this.onWeekdayHoursInputsChange}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>
                        {translate('common.cancel')}
                    </Button>
                    <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.save')}</Button>
                </ModalFooter>

                {showHistory && (
                    <PacoModal isWide>
                        <ModalContent
                            showCloseButton
                            title={translate('pages.absences.absenceLog')}
                            onCloseButtonClick={this.toggleShowHistory}
                        >
                            <ConnectedEntityEventLogs entityId={absence.id} />
                        </ModalContent>
                    </PacoModal>
                )}
            </form>
        );
    }
}

ResolveAbsenceForm.propTypes = {
    absence: PropTypes.object,
    currentUserRole: PropTypes.object.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ResolveAbsenceForm;
