import { AbsencesRoutes } from '../../../../pages/Absences/AbsencesRoutes';
import { PZRoutes } from '../../../../pages/PZ/PZRoutes';
import { TrackRoutes } from '../../../../pages/Tracks/TrackRoutes';
import { Notifications } from '../../../entities/Notifications/Notifications';
import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import { getPayrollPeriodFromDate } from '../../../entities/PayrollPeriod/PayrollPeriodHelpers';
import { Permission } from '../../../entities/Permission/Permission';
import { doesRouteHaveMissingPermissions } from '../../../helpers/routes';
import trans from '../../../helpers/trans';
import { ScheduleRoutes } from '../../../routes/helpers';
import { PrivateRouteGroupRoutes } from '../../../routes/routes';
import { DashboardSettingType } from '../../../types';
import { PermissionSlug } from '../../../types/permissionTypes';
import { DashboardSmallWidgetProps } from '../subcomponents/DashboardSmallWidget/DashboardSmallWidget';

const getSmallWidgetData = (
    type: DashboardSettingType,
    notifications: Notifications,
    userPermissions: PermissionSlug[],
    currentPeriod?: PayrollPeriod,
): DashboardSmallWidgetProps => {
    if (type === DashboardSettingType.absencesWithConflicts) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('absences-list', userPermissions);

        return {
            title: notifications.absencesWithConflicts.toString(),
            subTitle: trans('containers.smallWidgets.absencesWithConflicts'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.absences()}/${AbsencesRoutes.absencesList()}` }),
        };
    }

    if (type === DashboardSettingType.currentPayrollPeriodNumber) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('complete-periods', userPermissions);
        const { currentPayrollPeriodNumber } = notifications;
        const currentOpenActions = notifications.openTracksInPeriod
            + notifications.runningTracksInPeriod
            + notifications.openAbsenceHours
            + notifications.openLeaveOfAbsenceHours
            + notifications.openLeaveOfAbsencesInPeriod
            + notifications.incompleteUsers;

        return {
            icon: currentPeriod?.isLocked ? 'lock-closed' : 'lock-open',
            title: trans('containers.smallWidgets.period', { number: currentPayrollPeriodNumber ? currentPayrollPeriodNumber.toString() : '?' }),
            subTitle: trans('containers.smallWidgets.currentOpenActions', { currentOpenActions: currentOpenActions.toString() }),
            ...(currentOpenActions && { subTitleAmount: currentOpenActions.toString() }),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.pz()}/${PZRoutes.completePeriodDetail(currentPeriod?.id || '')}` }),
        };
    }

    if (type === DashboardSettingType.openAbsences) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('absences-list', userPermissions);

        return {
            title: notifications.openAbsences.toString(),
            subTitle: trans('containers.smallWidgets.openAbsences'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.absences()}/${AbsencesRoutes.absencesList()}` }),
        };
    }

    if (type === DashboardSettingType.openAbsenceHours) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('complete-periods', userPermissions);

        return {
            title: notifications.openAbsenceHours.toString(),
            subTitle: trans('containers.smallWidgets.openAbsenceHours'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.pz()}/${PZRoutes.completePeriodDetail(currentPeriod?.id || '')}` }),
        };
    }

    if (type === DashboardSettingType.openExchanges) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('exchange-requests', userPermissions);

        return {
            title: notifications.openExchanges.toString(),
            subTitle: trans('containers.smallWidgets.openExchangeRequests'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.absences()}/${AbsencesRoutes.exchangeRequests()}` }),

        };
    }
    if (type === DashboardSettingType.openLeaveOfAbsenceHours) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('complete-periods', userPermissions);

        return {
            title: notifications.openLeaveOfAbsenceHours.toString(),
            subTitle: trans('containers.smallWidgets.openLeaveOfAbsenceHours'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.pz()}/${PZRoutes.completePeriodDetail(currentPeriod?.id || '')}` }),
        };
    }

    if (type === DashboardSettingType.openLeaveOfAbsences) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('leave-of-absence-requests', userPermissions);

        return {
            title: notifications.openLeaveOfAbsences.toString(),
            subTitle: trans('containers.smallWidgets.openLeaveOfAbsenceRequests'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.absences()}/${AbsencesRoutes.leaveOfAbsenceRequests()}` }),
        };
    }

    if (type === DashboardSettingType.runningTracks) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('open-tracks', userPermissions);

        return {
            title: notifications.runningTracksInPeriod.toString(),
            subTitle: trans('containers.smallWidgets.openTracks'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.tracks()}/${TrackRoutes.open()}` }),

        };
    }

    if (type === DashboardSettingType.openTracks) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('review-tracks', userPermissions);

        return {
            title: notifications.openTracksInPeriod.toString(),
            subTitle: trans('containers.smallWidgets.reviewTracks'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.tracks()}/${TrackRoutes.review()}` }),
        };
    }

    if (type === DashboardSettingType.registeredShiftPlanningsForFourWeeks) {
        const routeHasMissingPermission = doesRouteHaveMissingPermissions('review-tracks', userPermissions);

        return {
            title: notifications.registeredShiftPlanningsForFourWeeks.toString(),
            subTitle: trans('containers.smallWidgets.registeredShiftPlanningsForFourWeeks'),
            ...(!routeHasMissingPermission && { path: `${PrivateRouteGroupRoutes.shifts()}/${ScheduleRoutes.availability()}` }),
        };
    }

    return {
        title: trans('common.unknown'),
        subTitle: '',
    };
};

export const getSmallWidgetsData = (
    types: DashboardSettingType[],
    notifications: Notifications,
    payrollPeriods: PayrollPeriod[],
    permissions: Permission[],
): DashboardSmallWidgetProps[] => {
    const currentPeriod = getPayrollPeriodFromDate(new Date(), payrollPeriods);
    const permissionSlugs = permissions.map(permission => permission.slug);

    return types.map(type => getSmallWidgetData(
        type,
        notifications,
        permissionSlugs,
        currentPeriod,
    ));
};
