import {
    AbsenceNotifications,
    AbsenceNotificationsResource,
    ExchangeNotifications,
    ExchangeNotificationsResource,
    FeedbackNotifications,
    FeedbackNotificationsResource,
    LeaveOfAbsenceNotifications,
    LeaveOfAbsenceNotificationsResource,
    PayrollPeriodNotifications,
    PayrollPeriodNotificationsResource,
    ShiftNotifications,
    ShiftNotificationsResource,
    TrackNotifications,
    TrackNotificationsResource,
} from './Notifications';

export const transformAbsenceNotifications = (absenceNotificationsResource: AbsenceNotificationsResource): AbsenceNotifications => ({
    openAbsences: absenceNotificationsResource.attributes.openAbsences,
    absencesWithConflicts: absenceNotificationsResource.attributes.absencesWithConflicts,
});

export const transformExchangeNotifications = (exchangeNotificationsResource: ExchangeNotificationsResource): ExchangeNotifications => ({
    openExchanges: exchangeNotificationsResource.attributes.openExchanges,
});

export const transformLeaveOfAbsenceNotifications = (leaveOfAbsenceNotificationsResource: LeaveOfAbsenceNotificationsResource): LeaveOfAbsenceNotifications => ({
    openLeaveOfAbsences: leaveOfAbsenceNotificationsResource.attributes.openLeaveOfAbsences,
    openLeaveOfAbsencesInPeriod: leaveOfAbsenceNotificationsResource.attributes.openLeaveOfAbsencesInPeriod,
});

export const transformPayrollPeriodNotifications = (leaveOfAbsenceNotificationsResource: PayrollPeriodNotificationsResource): PayrollPeriodNotifications => ({
    currentPayrollPeriodNumber: leaveOfAbsenceNotificationsResource.attributes.currentPayrollPeriodNumber || undefined,
});

export const transformFeedbackNotifications = (feedbackNotificationsResource: FeedbackNotificationsResource): FeedbackNotifications => ({
    satisfiedRating: feedbackNotificationsResource.attributes.satisfiedRating,
});

export const transformTrackNotifications = (trackNotificationsResource: TrackNotificationsResource): TrackNotifications => ({
    openTracks: trackNotificationsResource.attributes.openTracks,
    openTracksInPeriod: trackNotificationsResource.attributes.openTracksInPeriod,
    runningTracks: trackNotificationsResource.attributes.runningTracks,
    runningTracksInPeriod: trackNotificationsResource.attributes.runningTracksInPeriod,
});

export const transformShiftNotifications = (shiftNotificationsResource: ShiftNotificationsResource): ShiftNotifications => ({
    openShiftsForOneWeek: shiftNotificationsResource.attributes.openShiftsForOneWeek,
    openShiftsForFourWeeks: shiftNotificationsResource.attributes.openShiftsForFourWeeks,
    registeredShiftPlanningsForFourWeeks: shiftNotificationsResource.attributes.registeredShiftPlanningsForFourWeeks,
});

