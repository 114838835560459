import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon } from '../../../components';
import { ConnectedEditShiftTimeForm } from '../../../connectors';
import { ShiftIndex } from '../../../entities/Shift/Shift';
import { ShiftsCalendarAbsence } from '../../../entities/ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../../../entities/ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftsCalendarUnavailableToWorkTimeSlot } from '../../../entities/ShiftsCalendarUnavailableToWorkTimeSlot/ShiftsCalendarUnavailableToWorkTimeSlot';
import { getPersonFullName } from '../../../helpers';
import trans from '../../../helpers/trans';
import {
    CalendarShiftEmployee,
    CalendarShiftPlanning,
    CalendarShiftTemporaryWorker,
    EditTimeButton,
} from '../../index';
import { getCalendarShiftStatus, getRegisteredShiftPlanningsText } from './helpers';

import './CalendarShift.scss';

interface CalendarShiftProps {
    canEditShift?: boolean;
    draggable?: boolean;
    isPreview?: boolean;
    showEmployees?: boolean;
    showEmployeeEmploymentType?: boolean;
    absences: ShiftsCalendarAbsence[];
    leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[];
    shift: ShiftIndex;
    unavailableToWorkTimeSlots: ShiftsCalendarUnavailableToWorkTimeSlot[];
    onClick: (shift: ShiftIndex) => void;
    onDragStart?: (shift: ShiftIndex) => void;
    onDragEnd?: () => void;
    className?: string;
}

export enum ShiftStatusType {
    past = 'past',
    full = 'full',
    available = 'available'
}

const CalendarShift: FC<CalendarShiftProps> = ({
    canEditShift,
    draggable,
    isPreview,
    showEmployees,
    showEmployeeEmploymentType,
    absences,
    leaveOfAbsences,
    shift,
    unavailableToWorkTimeSlots,
    onClick,
    onDragStart,
    onDragEnd,
    className = '',
}): ReactElement => {
    const [showEditTimeForm, setShowEditTimeForm] = useState(false);

    const plannedShiftPlannings = useMemo(() => shift.shiftPlannings.filter((shiftPlanning) => shiftPlanning.planned), [shift]);
    const registeredShiftPlanningsText = useMemo(() => getRegisteredShiftPlanningsText(shift.shiftPlannings), [shift]);
    const { previouslyPlannedUsers } = shift;
    const shiftStatus = useMemo(() => getCalendarShiftStatus(shift), [shift]);
    const showRegisteredShiftPlanningsText = shiftStatus !== ShiftStatusType.past && registeredShiftPlanningsText && showEmployees && !isPreview;
    const plannedEmployeesCount = plannedShiftPlannings.length + shift.temporaryWorkers.length;

    const elementClassName = classNames('paco-calendar-shift', {
        [`paco-calendar-shift--is-${shiftStatus}`]: shiftStatus,
        'paco-calendar-shift--is-preview': isPreview,
        'paco-calendar-shift--show-registered-shift-plannings': showRegisteredShiftPlanningsText,
    }, className);

    const { name: departmentName, departmentGroup } = shift.department;
    const departmentClassNames = classNames('paco-calendar-shift__department', {
        'paco-calendar-shift__department--is-bold': !departmentGroup,
    });

    const handleClick = (): void => {
        onClick(shift);
    };

    const handleDragStart = (): void => {
        if (onDragStart) {
            onDragStart(shift);
        }
    };

    const handleTimeButtonClick = (): void => {
        setShowEditTimeForm(true);
    };

    const handleCloseSubmitEditShiftTimeForm = (): void => {
        setShowEditTimeForm(false);
    };

    return (
        <div
            draggable={draggable}
            onDragStart={handleDragStart}
            onDragEnd={onDragEnd}
            className={elementClassName}
        >
            <div className="paco-calendar-shift__main-info">
                <div className="paco-calendar-shift__time-and-people-count">
                    {showEditTimeForm ? (
                        <ConnectedEditShiftTimeForm
                            shift={shift}
                            onClose={handleCloseSubmitEditShiftTimeForm}
                            className="paco-calendar-shift__edit-time-form"
                        />
                        ) : (
                            <EditTimeButton
                                isDisabled={!canEditShift}
                                period={shift.period}
                                onClick={handleTimeButtonClick}
                                className="paco-calendar-shift__edit-time-button"
                            />
                    )}
                    <div className="paco-calendar-shift__people-count">
                        {`${plannedEmployeesCount}/${shift.peopleLimit}`}
                    </div>
                </div>

                <div className={departmentClassNames}>
                    {departmentGroup && (
                        <span className="paco-calendar-shift__department-group-name">
                            {`${departmentGroup.name} -`}
                        </span>
                    )}
                    {departmentName}
                </div>

                {(!!(plannedEmployeesCount || previouslyPlannedUsers.length) && showEmployees) && (
                    <ul className="paco-calendar-shift__planned-employees">
                        {plannedShiftPlannings.map(shiftPlanning => (
                            <CalendarShiftPlanning
                                isPreview={isPreview}
                                showEmploymentType={showEmployeeEmploymentType}
                                absences={absences}
                                color={shiftPlanning.color}
                                key={shiftPlanning.id}
                                leaveOfAbsences={leaveOfAbsences}
                                shiftPeriod={shift.period}
                                shiftPlanning={shiftPlanning}
                                unavailableToWorkTimeSlots={unavailableToWorkTimeSlots}
                                employmentTypeClassName="paco-calendar-shift__employment-type"
                                iconClassName="paco-calendar-shift__employee-icon"
                            />
                        ))}
                        {shift.temporaryWorkers.map(temporaryWorker => (
                            <CalendarShiftTemporaryWorker
                                showEmploymentType={showEmployeeEmploymentType}
                                key={temporaryWorker.id}
                                shiftPeriod={shift.period}
                                temporaryWorker={temporaryWorker}
                                employmentTypeClassName="paco-calendar-shift__employment-type"
                                iconClassName="paco-calendar-shift__employee-icon"
                            />
                        ))}
                        {previouslyPlannedUsers.map(previouslyPlannedUser => (
                            <CalendarShiftEmployee
                                isPreviouslyPlannedUser
                                key={previouslyPlannedUser.id}
                                name={getPersonFullName(previouslyPlannedUser.person)}
                                color={null}
                            />
                        ))}
                    </ul>
                )}
            </div>

            {showRegisteredShiftPlanningsText && (
                <div className="paco-calendar-shift__registered-employees-info">
                    {registeredShiftPlanningsText}
                </div>
            )}

            {draggable && <Icon name="draggable" className="paco-calendar-shift__draggable-icon" />}

            {/* Fix for FF: Element can not be draggable if there's a button inside of it */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                aria-label={trans('common.shift')}
                onClick={handleClick}
                className="paco-calendar-shift__button"
            />
        </div>
    );
};

export default CalendarShift;
